import { apiPost } from 'src/shared/utils/api-request';

export const getAlertsListAPI = async (data: any) => {
  const res = await apiPost('alerts', data);
  return res;
};

export const getActionsListAPI = async () => {
  const res = await apiPost('actions/get-actions');
  return res;
};

export const actionPolicyAPI = async (data: any) => {
  const res = await apiPost('actions/action-policy', data);
  return res;
};
