import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  actionPolicyAPI,
  getActionsListAPI,
  getAlertsListAPI
} from '../apis/actions';
import {
  showErrorMessage,
  showSuccessMessage
} from 'src/shared/components/toaster';
import { generateCliAPI } from 'src/modules/Remediations/apis/remediations';

interface RemediationsState {
  remediatedAlerts: any[];
  loadingRemediatedAlerts: boolean;
  lastEvaluatedKey: any;
  totalCount: number;
  currentPage: number;
  loadingActions: boolean;
  actions: any[];
  view: number;
  selectedResource: any;
  selectedAction: any;
  actionPolicyData: any;
  isActionPolicySuccess: boolean;
  loadingCliData: boolean;
  cliData: any[];
}
const initialState: RemediationsState = {
  remediatedAlerts: [] as any[],
  loadingRemediatedAlerts: false,
  lastEvaluatedKey: {} as any,
  totalCount: 0,
  currentPage: 0,
  loadingActions: false,
  actions: [] as any[],
  view: 0,
  selectedResource: {} as any,
  selectedAction: {} as any,
  actionPolicyData: {} as any,
  isActionPolicySuccess: false,
  loadingCliData: false,
  cliData: [] as any[]
};

export const getRemediatedAlerts = createAsyncThunk(
  'actions/getRemediatedAlerts',
  async (data: any) => {
    const response = await getAlertsListAPI(data);
    return response.data;
  }
);

export const getActions = createAsyncThunk('actions/getActions', async () => {
  const response = await getActionsListAPI();
  return response.data;
});

export const applyActionPolicy = createAsyncThunk(
  'actions/applyActionPolicy',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await actionPolicyAPI(data);
      showSuccessMessage(response.data?.details);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      showErrorMessage(err.response.data?.details);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCLIData = createAsyncThunk(
  'actions/getCLIData',
  async (data: any) => {
    const response = await generateCliAPI(data);
    return response.data;
  }
);

export const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.loadingRemediatedAlerts = false;
      state.lastEvaluatedKey = {} as any;
      state.totalCount = 0;
      state.currentPage = 0;
      state.remediatedAlerts = [] as any[];
      state.actions = [] as any[];
      state.view = 0;
      state.selectedResource = {} as any;
      state.selectedAction = {} as any;
      state.actionPolicyData = {} as any;
      state.isActionPolicySuccess = false;
      state.cliData = [] as any[];
    },
    clearSelection: (state) => {
      state.selectedResource = {} as any;
      state.selectedAction = {} as any;
      state.actionPolicyData = {} as any;
      state.isActionPolicySuccess = false;
    },
    setSelectedResource: (state, action) => {
      state.selectedResource = action.payload;
    },
    setSelectedAction: (state, action) => {
      state.selectedAction = action.payload;
    },
    setActionPolicyData: (state, action) => {
      state.actionPolicyData = action.payload;
    },
    setActionsView: (state, action) => {
      state.view = action.payload;
    },
    setIsActionPolicySuccess: (state, action) => {
      state.isActionPolicySuccess = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRemediatedAlerts.pending, (state) => {
        state.loadingRemediatedAlerts = true;
      })
      .addCase(
        getRemediatedAlerts.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingRemediatedAlerts = false;
          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d, index) => {
              return { id: index, ...d };
            });
            state.remediatedAlerts = data;
            if (action.payload.last_evaluated_key) {
              state.lastEvaluatedKey = action.payload.last_evaluated_key;
            }
            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getRemediatedAlerts.rejected, (state) => {
        state.loadingRemediatedAlerts = false;
      })
      .addCase(getActions.pending, (state) => {
        state.loadingActions = true;
      })
      .addCase(
        getActions.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingActions = false;
          if (action.payload) {
            state.actions = action.payload;
          }
        }
      )
      .addCase(getActions.rejected, (state) => {
        state.loadingActions = false;
      })
      .addCase(applyActionPolicy.pending, (state) => {
        state.loadingActions = true;
      })
      .addCase(
        applyActionPolicy.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingActions = false;
          if (action.payload) {
            state.isActionPolicySuccess = true;
          }
        }
      )
      .addCase(applyActionPolicy.rejected, (state) => {
        state.loadingActions = false;
      })
      .addCase(getCLIData.pending, (state) => {
        state.loadingCliData = true;
      })
      .addCase(
        getCLIData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingCliData = false;
          if (action.payload) {
            state.cliData = action.payload;
          }
        }
      )
      .addCase(getCLIData.rejected, (state) => {
        state.loadingCliData = false;
      });
  }
});

export const {
  setCurrentPage,
  clearState,
  setSelectedResource,
  setActionsView,
  setSelectedAction,
  setActionPolicyData,
  setIsActionPolicySuccess,
  clearSelection
} = actionsSlice.actions;
export default actionsSlice.reducer;
