export const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;
export const DEPLOYMENT_ENV = process.env.REACT_APP_DEPLOYMENT_ENV;
export const INTERNAL_AUTH_TOKEN = process.env.REACT_APP_INTERNAL_AUTH_TOKEN;

export const AWS_REGION = 'us-west-2';
export const DB_TYPE = '?db_type=athena_v2';

//routes
export const LANDING_PAGE = import('src/modules/LandingPage');
export const LOGIN = import('src/auth/login');
export const REGISTER = import('src/auth/register');
export const FORGOT_PASSWORD = import('src/auth/forgotPassword/forgotPassword');
export const RESET_PASSWORD = import('src/auth/forgotPassword/resetPassword');
export const DASHBOARD = ['DEV', 'DEMO'].includes(DEPLOYMENT_ENV)
  ? import('src/modules/Dashboard/DashboardV2')
  : import('src/modules/Dashboard');
export const IDENTITY_INVENTORY = import('src/modules/IdentityInventory');
export const RUNTIME = import('src/modules/Runtime');
export const AWS_ALERTS = import('src/modules/AwsAlertsV2');
export const INVESTIGATION = import(
  'src/modules/InvestigationChangeManagementV2/Index'
);
export const POLICIES = import('src/modules/PoliciesV2');
export const ALERT_DETAILS = import(
  'src/modules/AwsAlertsV2/components/AlertDetailsV4'
);
export const IDENTITY_FINGERPRINT = import('src/modules/IdentityFingerprintV2');
export const USAGE_ENTITLEMENT = import('src/modules/IdentityFingerprint');
export const SETTINGS = import('src/modules/SettingsV2');
export const ACTIVITY_LOGS = import('src/modules/ActivityLog');
export const PROFILE_MANAGEMENT = import(
  'src/modules/Settings/ProfileManagement/components/ProfileManagement'
);
export const ORGANIZATION_MANAGEMENT = import(
  'src/modules/Settings/OrganizationManagement'
);
export const CHANGE_PASSWORD = import(
  'src/modules/Settings/ChangePassword/components/Changepassword'
);
export const CHANGE_MANAGEMENT = import('src/modules/ChangeManagement');
export const PRIVILEGE_MANAGEMENT = import('src/modules/PrivilegeManagement');
export const INTEGRATIONS = import(
  'src/modules/SettingsV2/components/Integrations'
);
export const REMEDIATION = import('src/modules/Remediations');
export const ACTIONS = import('src/modules/Actions');

//images
export const LOGO_IMAGE = require('../../assets/images/logo.png');
export const LANDING_PAGE_LOGO_IMAGE = require('../../assets/images/logo-landing-page.png');

export const PAGINATION_LIMIT = 10;
export const RAW_DATA_PAGINATION_LIMIT = 25;
export const INPUT_DATE_TIME_FORMAT = 'YYYY-MM-DD hh-mm-ss.SSS';
export const OKTA_INPUT_DATE_TIME_FORMAT = 'YYYY-MM-DD hh-mm-ss';
export const FORENSIC_API_DATE_TIME_FORMAT = 'YYYY-MM-DD-HH-mm-ss.SSS';
export const DATE_TIME_FORMAT = 'MM-DD-YYYY HH:mm:ss';
export const EPOCH_TIMESTAMP_MULTIPLIER = 10000000;
export const COMPLETED = 'completed';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export enum statusLable {
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed'
}

export const RUNTIME_API_TIME = DEPLOYMENT_ENV === 'DEV' ? 270 : 1;
export const DASHBOARD_API_TIME = DEPLOYMENT_ENV === 'DEV' ? 48 : 24;

export const ROLES = [
  { id: 'admin', label: 'Admin' },
  { id: 'user', label: 'User' }
];

export const ACTIVITY_TYPES = [
  { id: 'LOGIN', label: 'Login' },
  { id: 'CREATE_ACCOUNT', label: 'Create Account' },
  { id: 'DELETE_ACCOUNT', label: 'Delete Account' },
  { id: 'UPDATE_ACCOUNT', label: 'Update Account' }
];

export const ALL_TAB_ALERT_FILTERS = [
  { id: 'rule_name', label: 'Rule Name', type: 'text', value: '' },
  { id: 'resource', label: 'Resource', type: 'text', value: '' },
  { id: 'data_source', label: 'Source Type', type: 'select', value: '' },
  { id: 'policy_type', label: 'Rule Type', type: 'select', value: '' },
  { id: 'rule_type', label: 'Alert Type', type: 'select', value: '' },
  { id: 'priority', label: 'Priority', type: 'select', value: '' },
  { id: 'alert_status', label: 'Alert Status', type: 'select', value: '' }
];

export const ALERT_FILTERS = [
  { id: 'rule_name', label: 'Rule Name', type: 'text', value: '' },
  { id: 'resource', label: 'Resource', type: 'text', value: '' },
  { id: 'data_source', label: 'Source Type', type: 'select', value: '' },
  { id: 'policy_type', label: 'Rule Type', type: 'select', value: '' },
  { id: 'rule_type', label: 'Alert Type', type: 'select', value: '' },
  { id: 'priority', label: 'Priority', type: 'select', value: '' }
];

export const ALL_TAB_NON_HUMAN_ALERT_FILTERS = [
  { id: 'rule_name', label: 'Rule Name', type: 'text', value: '' },
  { id: 'resource', label: 'Resource', type: 'text', value: '' },
  { id: 'data_source', label: 'Source Type', type: 'select', value: '' },
  { id: 'policy_type', label: 'Rule Type', type: 'select', value: '' },
  { id: 'rule_type', label: 'Alert Type', type: 'select', value: '' },
  { id: 'priority', label: 'Priority', type: 'select', value: '' },
  { id: 'alert_status', label: 'Alert Status', type: 'select', value: '' }
];

export const NON_HUMAN_ALERT_FILTERS = [
  { id: 'rule_name', label: 'Rule Name', type: 'text', value: '' },
  { id: 'resource', label: 'Resource', type: 'text', value: '' },
  { id: 'data_source', label: 'Source Type', type: 'select', value: '' },
  { id: 'policy_type', label: 'Rule Type', type: 'select', value: '' },
  { id: 'rule_type', label: 'Alert Type', type: 'select', value: '' },
  { id: 'priority', label: 'Priority', type: 'select', value: '' }
];

export const DATA_SOURCES = [
  { label: 'AWS', value: 'AWS' },
  { label: 'GCP', value: 'GCP' },
  { label: 'AZURE', value: 'AZURE' },
  { label: 'OKTA', value: 'OKTA' },
  { label: 'VPC', value: 'VPC' },
  { label: 'Entra ID', value: 'ENTRA' },
  { label: 'AWS GuardDuty', value: 'AWS GuardDuty' },
  { label: 'Microsoft 365', value: 'Microsoft365' },
  { label: 'Google Workspace', value: 'GoogleWorkspace' }
];

export const IDENTITY_DATA_SOURCES = [
  { label: 'IAM', value: 'IAM' },
  { label: 'SSO', value: 'SSO' },
  { label: 'GCP', value: 'GCP' },
  { label: 'AZURE', value: 'AZURE' },
  { label: 'OKTA', value: 'OKTA' },
  { label: 'VPC', value: 'VPC' },
  { label: 'Entra ID', value: 'ENTRA' },
  { label: 'AWS GuardDuty', value: 'AWS GuardDuty' },
  { label: 'Microsoft 365', value: 'Microsoft365' },
  { label: 'Google Workspace', value: 'GoogleWorkspace' }
];

export const IDENTITY_STATUS = [
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'INACTIVE', value: 'INACTIVE' },
  { label: 'SUSPENDED', value: 'SUSPENDED' }
];

export const YES_NO = [
  { label: 'Yes', value: 'True' },
  { label: 'No', value: 'False' }
];

export const RULE_TYPES = [
  { label: 'Rule', value: 'RULE' },
  { label: 'TTP', value: 'TTP' },
  { label: 'Anomaly', value: 'ANOMALY' },
  { label: 'Third Party', value: 'THIRD_PARTY' }
];

export const ALERT_PRIORITY = [
  { label: 'Critical', value: 'CRITICAL' },
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' }
];

export const ALERT_STATUS = [
  { label: 'Open', value: 'OPEN' },
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Assigned To', value: 'ASSIGNED_TO' },
  { label: 'Remediated', value: 'REMEDIATED' },
  { label: 'Closed', value: 'CLOSED' }
];

export const VPC_FLOW_DIRECTION = ['ingress', 'egress'];

export const MICROSOFT_365_EVENT_SOURCE = [
  'MicrosoftOffice365',
  'MicrosoftExchange',
  'MicrosoftSharePoint'
];

export const GOOGLE_WORKSPACE_EVENT_SOURCE = [
  'login.googleapis.com',
  'logging.googleapis.com',
  'admin.googleapis.com',
  'drive.googleapis.com',
  'gmail.googleapis.com',
  'meet.googleapis.com',
  'calendar.googleapis.com',
  'chat.googleapis.com',
  'classroom.googleapis.com',
  'drive_labels.googleapis.com',
  'groups.googleapis.com',
  'contextawareaccess.googleapis.com',
  'nftest-cloudasset.sandbox.googleapis.com',
  'cloudasset.googleapis.com',
  'cloudresourcemanager.googleapis.com'
];

export const DISTRIBUTION_KEYS: Record<string, string[]> = {
  AWS: [
    'operation',
    'resource',
    'source_ip_address',
    'event_source',
    'api_name',
    'role_arn',
    'principal_arn',
    'policy_type'
  ],
  GCP: [
    'operation',
    'resource',
    'source_ip_address',
    'event_source',
    'api_name',
    'service_account',
    'principal_arn',
    'policy_type'
  ],
  AZURE: [
    'operation',
    'resource',
    'source_ip_address',
    'event_source',
    'api_name',
    'principal_arn',
    'policy_type'
  ],
  ENTRA: [
    'operation',
    'resource',
    'source_ip_address',
    'event_source',
    'api_name',
    'principal_arn',
    'policy_type'
  ],
  OKTA: ['resource', 'source_ip_address', 'api_name'],
  VPC: [
    'source_port',
    'flow_direction',
    'action',
    'source_ip_address',
    'destination_port',
    'destination_ip_address',
    'bytes'
  ],
  GoogleWorkspace: [
    'operation',
    'resource',
    'source_ip_address',
    'event_source',
    'api_name',
    'principal_arn'
  ],
  Microsoft365: [
    'resource',
    'source_ip_address',
    'event_source',
    'api_name',
    'principal_arn'
  ]
};

export const DISTRIBUTION_KEY_MAPPING = [
  { key: 'operation', value: 'Operation' },
  { key: 'resource', value: 'Resource' },
  { key: 'source_ip_address', value: 'Source IP' },
  { key: 'event_source', value: 'Event Source' },
  { key: 'api_name', value: 'API' },
  { key: 'role_arn', value: 'Role' },
  { key: 'principal_arn', value: 'Principal' },
  { key: 'policy_type', value: 'Sensitive Calls' },
  { key: 'source_port', value: 'Source Port' },
  { key: 'flow_direction', value: 'Flow Direction' },
  { key: 'action', value: 'Action' },
  { key: 'destination_port', value: 'Destination Port' },
  { key: 'destination_ip_address', value: 'Destination IP' },
  { key: 'bytes', value: 'Bytes' },
  { key: 'service_account', value: 'Service Account' }
];

export const ACTION_FORM_STEPS = {
  LIST: 0,
  ACTION: 1,
  FORM: 2,
  CONFIRMATION: 3
};

export const AIR_RISK_SCORE_KEY_MAPPING: Record<string, string> = {
  identity_score:
    'Measures the potential threat based on identity-related factors, such as abnormal login patterns or suspicious account behavior. The higher the score, the more likely that the identity is compromised.',
  resource_score:
    'Evaluates the criticality of the systems, data, or assets involved. A higher score reflects more sensitive or vital resources being impacted, which poses a greater risk.',
  activity_score:
    'Assesses how unusual or potentially harmful the detected activity is. A higher score indicates that the behavior significantly deviates from normal patterns and warrants closer inspection.'
};

export const RISK_SCORE_CHART = [
  { score: 'Low', value: '0 to 4' },
  { score: 'Medium', value: '5 to 6.5' },
  { score: 'High', value: '6.5 to 8' },
  { score: 'Critical', value: '8 to 10' }
];
